/* Large desktops and laptops */
@media (min-width: 1200px) {
  .map-view {
    padding: 0;
    width: 100vw;
    height: 100vh;
  }
  ._modal .modal-content {
    max-width: 400px;
  }
}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  ._modal .modal-content {
    max-width: 400px;
  }
}
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .map-view {
    padding: 0;
  }
  ._modal .modal-content {
    max-width: 400px;
  }
}
/* Landscape phones and portrait tablets */
/* Portrait phones and smaller */
@media (max-width: 500px) {
  .map-view {
    padding: 0;
    /*transform: scale(2);
    -webkit-transform: scale(2);
    -moz-transform: scale(2);*/
  }
  .map-view .map-widgets .icon-widget {
    /* transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);*/
  }
  ._modal .modal-content {
    max-width: 85% !important;
  }
}
